import classNames from 'classnames'
import Button from 'components/Button'
import { useContactExpert } from 'components/modal/contact-expert'
import React, { FC } from 'react'
import { FaCircleChevronRight } from 'react-icons/fa6'
import { openSignup } from 'utils/pr'
import css from './cta-button.module.scss'

export type CtaButtonAction = 'trial' | 'api-contact'

export type CtaButtonType = 'large' | 'medium' | 'small' | 'black' | 'blue' | 'solid' | 'green' | 'green-small'

/**
 * Call-To-Action button. Default props create a 7-Day Trial button that opens the trial dialog.
 */
const CtaButton: FC<{
  action?: CtaButtonAction
  type?: CtaButtonType
  label?: string
}> = ({
  action = 'trial',
  label = action === 'api-contact' ? 'Talk to an Expert' : '7 Day Free Trial',
  type = 'medium',
}) => {
  const { openContactExpert, contactExpertElement } = useContactExpert()

  return (
    <>
      <Button
        className={classNames(css.frame, css[`type-${type}`])}
        onClick={action === 'api-contact' ? openContactExpert : openSignup}
        target="signup"
        solid={type === 'solid'}
      >
        {label}
        <FaCircleChevronRight size={type === 'small' ? 22 : (type === 'medium' ? 26 : 34)} />
      </Button>
      {contactExpertElement}
    </>
  )
}

export default CtaButton
