'use client'

import { Inter } from '@next/font/google'
import classNames from 'classnames'
import Icon from 'components/icon'
import { Checkbox } from 'components/input'
import Suspense, { SuspenseProps } from 'components/suspense'
import Image from 'next/image'
import React, { DependencyList, FC, FormEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { Portal } from 'react-portal'
import { debounce } from 'utils/dom'
import check from 'public/check.png'

import css from './modal.module.scss'

export type ModalType = 'centered' | 'full' | 'inline' | 'slide-left' | 'slide-right'
export type ModalCloseOrigin = 'escape' | 'background-click' | 'icon' | 'button' | 'completion'

const inter = Inter({ weight: ['300', '500', '600', '700', '800', '900'], subsets: ['latin']})

export interface ModalProps {
  buttons?: ReactNode
  buttonsSuppressed?: boolean
  children?: ReactNode
  className?: string
  close?: (item?: any) => void
  closeButton?: boolean
  closeLabel?: string
  complete?: boolean
  confirmation?: boolean
  contentClassName?: string
  copy?: boolean
  deleteLabel?: string
  description?: string | undefined
  closeDelay?: number
  completeDelay?: number
  formId?: string
  loading?: boolean
  okLabel?: string | undefined
  onSubmit?: ((ev: FormEvent) => void) | undefined
  onClose?: ((origin: ModalCloseOrigin) => void | boolean) | undefined
  onDelete?: () => void
  onCloseButtonClick?: (() => void) | undefined
  onOk?: (() => void) | undefined
  opened?: boolean
  overflowVisible?: boolean
  padding?: number
  popupClassName?: string
  setCopy?: ((copy: boolean) => void) | undefined
  scrollDeps?: DependencyList
  suspense?: SuspenseProps | undefined
  type?: ModalType
  title?: string | undefined
}

const Modal: FC<ModalProps> = ({
  buttons,
  buttonsSuppressed = false,
  children,
  className,
  close,
  closeButton = true,
  closeLabel,
  complete = false,
  confirmation = false,
  contentClassName,
  copy = false,
  deleteLabel = 'Delete',
  description,
  closeDelay = 250,
  completeDelay = 400,
  formId,
  loading = false,
  okLabel = 'OK',
  onClose,
  onDelete,
  onSubmit,
  onOk,
  opened = true,
  overflowVisible = false,
  padding,
  popupClassName,
  scrollDeps = [],
  setCopy,
  suspense,
  type = 'centered',
  title,
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<HTMLFormElement | null>(null)
  const [mounted, setMounted] = useState(false)
  const [visible, setVisible] = useState(false)

  // Fade popup out and alert caller of closure
  const triggerClose = () => {
    setVisible(false)

    if (close != null) debounce(close, closeDelay)
  }

  // Initiate closure if allowed (popup isn't disabled / in loading state and close method provided)
  const handleClose = (origin: ModalCloseOrigin) => {
    if (opened && (complete || (!loading && close != null))) {
      if (onClose !== undefined && onClose(origin) === true) return

      triggerClose()
    }
  }

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    ev.stopPropagation()

    if (ev.key === 'Escape') handleClose('escape')
  }

  // Respond to "opened" flag status
  useEffect(() => {
    const el = ref.current
    if (mounted && el != null) {
      if (!opened) triggerClose()
      else {
        // Focus keyboard to popup (So Escape works)
        el.focus()

        // Using separate visibility flag (rather than just the "opened" status) to allow for show/hide animation css transition effects
        setVisible(true)
      }
    }
  }, [opened, mounted])

  // Response to "complete" status; when complete is set to true interface changes for a brief period to signal
  // completion to user before fading popup out
  useEffect(() => {
    if (complete) debounce(() => handleClose('completion'), completeDelay)
  }, [complete])

  // Set css vars
  useEffect(() => {
    if (popupRef.current != null) {
      ref.current?.style.setProperty('--popup-width', `${popupRef.current?.offsetWidth}px`)
      // ref.current?.style.setProperty('--close-delay', `${closeDelay}ms`)
    }
  }, [popupRef, closeDelay])

  // Accommodate SSR; Don't render portal on initial render to avoid hydration error.
  useEffect(() => {
    debounce(() => setMounted(true))
  }, [])

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault()

    if (onSubmit != null) onSubmit(ev)
  }

  useEffect(() => {
    // Allow caller to trigger popup scrolling to top; e.g. when navigating between steps in a wizard.
    if (popupRef != null) popupRef.current?.scrollTo({ top: 0 })
  }, scrollDeps);

  // if (typeof window === 'undefined') return null
  if (!mounted) return null

  const disabled = loading || complete
  const buttonProps = { loading, solid: true }
  const showButtons = !buttonsSuppressed && (onOk != null || onSubmit != null || onDelete != null)
  const completeLabel = okLabel === 'Submit' ? 'Complete' : 'Saved'

  const modal = (
    <div
      className={classNames(inter.className, css.modal, css[`type-${type}`], {
        [css.confirmation]: confirmation,
        [css.disabled]: disabled,
        [css.visible]: visible,
      }, className)}
      onMouseDown={() => handleClose('background-click')}
      onKeyDown={handleKeyDown}
      ref={ref}
      tabIndex={0}
    >
      <form
        className={classNames(css.popup, {
          [css.overflowVisible]: overflowVisible,
        }, popupClassName)}
        id={formId}
        onMouseDown={ev => ev.stopPropagation()}
        onSubmit={handleSubmit}
        ref={popupRef}
        style={{ padding }}
      >
        {confirmation && <Image src={check} alt="Confirmed" className={css.topImage} unoptimized />}
        {title != null && (
          <div className={css.modalHeader}>
            <div className={css.modalTitle}>{title}</div>
          </div>
        )}
        {description != null && <div className={css.description}>{description}</div>}
        <div className={classNames(css.modalContent, contentClassName)}>
          {children}
        </div>
        {(showButtons || setCopy != null) && (
          <div className={css.modalFooter}>
            <div>
              {setCopy != null && <Checkbox checked={copy} onChange={setCopy == null ? undefined : ev => setCopy(ev.checked)} label="Save Copy" />}
            </div>
            {showButtons && (
              <div>
                {buttons}
                {/*{closeButton && closeLabel !== '' && <Link onClick={() => handleClose('button')}>{closeLabel ?? (onDelete == null ? 'Close' : 'Cancel')}</Link>}*/}
                {/*{onDelete != null && <Button {...buttonProps} danger onClick={onDelete}>{complete ? 'Deleted' : deleteLabel}</Button>}*/}
                {/*{(onOk != null || onSubmit != null) && <Button {...buttonProps} onClick={onOk} submit={onSubmit != null}>{complete ? completeLabel : okLabel}</Button>}*/}
              </div>
            )}
          </div>
        )}
        {closeButton && close != null && <Icon className={css.close} onClick={() => handleClose('icon')} preset="close" />}
        {suspense !== undefined && <Suspense {...suspense} />}
      </form>
    </div>
  )

  return type === 'inline' ? modal : <Portal>{modal}</Portal>
}

export const useModal = (defaultOpened = false) => {
  const [modalOpened, setModalOpened] = useState(defaultOpened)

  return {
    closeModal: () => setModalOpened(false),
    modalOpened,
    openModal: () => setModalOpened(true),
    setModalOpened,
  }
}

export default Modal
